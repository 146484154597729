// import React, { useState, useEffect, useRef } from "react";
// const styles = {
//   textField: { width: "100%", borderWidth: 0, borderColor: "transparent" },
//   textFieldContainer: { flex: 1, marginRight: 12 },
//   gridItem: { paddingTop: 12, paddingBottom: 12 },
//   gridItemChatList: { overflow: "auto", height: "74vh",width:"-webkit-fill-available" },
//   gridItemMessage: { marginTop: 12, marginBottom: 12 },
//   sendButton: { backgroundColor: "transparent" },
//   sendIcon: { color: "white" },
//   mainGrid: { paddingTop: 100, borderWidth: 1 },


//   buttonContainer: {
//     position: 'fixed',
//     bottom:'8px',
//     left: 0,
//     right: 0,
//     display: 'grid',
//     justifyContent: 'center',
//     backgroundColor: 'transparent',
//   },

  
// };

// function extractSpecificUsername(email) {
//   // Regular expression to match the username part after the slash
//   var usernameRegex = /\/([^@\/]+)/;
  
//   // Extracting the username using match method
//   var usernameMatch = email.match(usernameRegex);
  
//   // If a match is found, return the username, otherwise return null
//   return usernameMatch ? usernameMatch[1] : null;
// }


// function extractLastPathPart(url) {
//   const urlParts = url.split('/');
//   return urlParts[urlParts.length - 1];
// }


// function getStringBeforeAtSymbol(inputString) {
//   const atIndex = inputString.indexOf('@');
//   if (atIndex === -1) {
//     return inputString; // return the original string if '@' is not found
//   }
//   return inputString.substring(0, atIndex);
// }

// const ChatScreen = ({ username, socket }) => {
//   const [text, setText] = useState("");
//   const [messages, setMessages] = useState([]);
//   const [usernameid, setUsernameid] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [channel, setChannel] = useState(null);
//   const [ProgramInfo, setProgramInfo] = useState(null);



//   console.log("messages->",messages)
//   // step1
//   const [floatingEmojis, setFloatingEmojis] = useState([]);


//   const [messageInput, setMessageInput] = useState('');
//   const [roomname, setRoomname] = useState('');


//   const scrollDiv = useRef(null);
//   const Button1Ref = useRef(null);
//   const Button2Ref = useRef(null);
//   const Button3Ref = useRef(null);
//   const Button4Ref = useRef(null);
//   const Button5Ref = useRef(null);
//   const Button6Ref = useRef(null);
//   const Button7Ref = useRef(null);
//   const Button8Ref = useRef(null);
//   const Button9Ref = useRef(null);
//   const Button10Ref = useRef(null);

// console.log("floatingEmojis->",floatingEmojis)

//   const CurrentUser = localStorage.getItem("email");
//   console.log("CurrentUser-->",CurrentUser)
  
//   useEffect(() => {

//     const storedEmail = localStorage.getItem('userId');
//     const storeProgramInfo = localStorage.getItem("ProgramInfo")
//     setProgramInfo(storeProgramInfo)
//     const username = storedEmail.split("@")[0];

//     setUsernameid(username)
//     console.log("usernameid-->",usernameid);

//     const roomid = localStorage.getItem('roomid');
//     setRoomname(roomid)

//     const handleKeyPress = (event) => {
//       if (event.key === "1") {
//         Button1Ref.current.click();
//         console.log("1 hit")
//       } else if (event.key === "2") {
//         Button2Ref.current.click();
//         console.log("2 hit")
//       } else if (event.key === "3") {
//         Button3Ref.current.click();
//         console.log("3 hit")
//       } else if (event.key === "4") {
//         Button4Ref.current.click();
//         console.log("4 hit")
//       } else if (event.key === "5") {
//         Button5Ref.current.click();
//         console.log("5 hit")
//       }
//        else if (event.key === "6") {
//         Button6Ref.current.click();
//         console.log("6 hit")
//       } else if (event.key === "7") {
//         Button7Ref.current.click();
//         console.log("7 hit")
//     } else if (event.key === "8") {
//       Button8Ref.current.click();
//       console.log("8 hit")
//     } else if (event.key === "9") {
//       Button9Ref.current.click();
//       console.log("9 hit")
//     } else if (event.key === "0") {
//       Button10Ref.current.click();
//       console.log("0 hit")
//     }
//     };


//     window.addEventListener("keypress", handleKeyPress);

//     return () => {
//       window.removeEventListener("keypress", handleKeyPress);
//     };
    
//   }, []);

//   const showFloatingEmoji = (imageUrl) => {
//     if (imageUrl.endsWith('.png')) return; //not flying stickers
//     const screenWidth = window.innerWidth;
//     const maxOffset = screenWidth / 4;
//     const offset = Math.random() * maxOffset * (Math.random() < 0.5 ? -1 : 1);
//     const container = document.createElement('div');
//     container.className = 'floating-emoji-container';
//     container.style.left = `calc(50% + ${offset}px)`;
//     container.style.transform = 'translateX(-50%)';
//     const floatingEmoji = document.createElement('img');
//     floatingEmoji.className = 'floating-emoji';
//     floatingEmoji.src = imageUrl;
//     floatingEmoji.alt = 'Emoji';
//     floatingEmoji.style.width = '60px'; 
//     floatingEmoji.style.height = '60px'; 
//     container.appendChild(floatingEmoji);
//     document.body.appendChild(container);
//     const expirationTime = Date.now() + 2000; 
//     setFloatingEmojis([...floatingEmojis, { container, expirationTime }]);
    
   
//     const cardRect = document.querySelector('.card').getBoundingClientRect();
//     const containerRect = container.getBoundingClientRect();
//     const emojiRect = floatingEmoji.getBoundingClientRect();
  
//     if (containerRect.bottom > cardRect.top) {
//       // Emoji overlaps with card, move it above the card
//       const newTop = cardRect.top - emojiRect.height - 10; // 10 is the buffer space
//       container.style.top = `${newTop}px`;
//     }
//   };
  
  


//   useEffect(() => {
//     const handleMessage = (message) => {

//       console.log("test1-message",message)
//       const username = message.from; // Extracting the username
//       console.log("Ujjwal Res-->",extractSpecificUsername(username));
//       showFloatingEmoji(message.message);
//       setMessages(prevMessages => [...prevMessages, message]); //stickers
//     };

//     socket.on("send-message-to-client", handleMessage);
//     console.log("handleMessage 5->",handleMessage)

//     return () => {
//       socket.off("send-message-to-client", handleMessage);
//     };
//   }, [socket]); 



//     const sendMessage = (messageToSend) => {
//     if (messageToSend) {
//       socket.emit("receive-message-from-client", messageToSend);
//       console.log("messageToSend 4->",messageToSend)
//       setMessageInput('');
//     }
    
//   };


//   const handleMessageAdded = (message) => {
//     setMessages((prevMessages) => [...prevMessages, message]);
//     scrollToBottom();
//   };



//   const scrollToBottom = () => {
//     const scrollHeight = scrollDiv.current.scrollHeight;
//     const height = scrollDiv.current.clientHeight;
//     const maxScrollTop = scrollHeight - height;
//     scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
//   };





//   return (
// <>

// <div class="container-fluid">
//   <div>
//         <div class="col-6 chatitem" >
//         {messages.map((msg, index) => {
//           const userName = getStringBeforeAtSymbol(extractLastPathPart(msg.from));
//           const alignStyle = CurrentUser === extractLastPathPart(msg.from) ? 'end' : 'left';
//           const marginRStyle =  getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
//           const marginTStyle =  getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
//           // Check if the message is a .gif
//             const isGif = msg.message.endsWith('.gif');

//             if (isGif) {
//               return null;
//             }
//           return (
//             <div key={index} style={{ textAlign: alignStyle,marginRight: marginRStyle }}>
//               <img src={msg.message} alt="gif" width="75" height="75" />
//               <span className="autherusername">{userName}</span>
//             </div>
//           );
//         })}


//     </div>
//   </div>
//   <div class="row">
//     <div class="col-6 text-sm-end" >
//     </div>
//   </div>
  
// </div>

// <footer class="fixed-bottom p-3">
//   <div class="container-fluid">
//     <div class="row justify-content-center">
//       <div class="col-auto">
//       <div class="card">
//         <h1>tProgramInfo-{ProgramInfo}</h1>
//             <div class="card-body">
//               <div>
//               <a  ref={Button1Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/sticker1.png")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/sticker1.png" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>1</p1></span>
//         </a>

//         <a  ref={Button2Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/sticker2.png")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/sticker2.png" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>2</p1></span>
//         </a>

//         <a  ref={Button3Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/sticker3.png")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/sticker3.png" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>3</p1></span>
//         </a>

//         <a  ref={Button4Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/sticker4.png")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/sticker4.png" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>4</p1></span>
//         </a>

//         <a  ref={Button5Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/sticker6.png")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/sticker6.png" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>5</p1></span>
//         </a>
//                 </div>
//         <br/>

//                 <div>
//         <a  ref={Button6Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/emoji6.gif")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/emoji6.gif" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>6</p1></span>
//         </a>

//         <a  ref={Button7Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/emoji7.gif")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/emoji7.gif" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>7</p1></span>
//         </a>

//         <a  ref={Button8Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/emoji8.gif")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/emoji8.gif" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>8</p1></span>
//         </a>

//         <a  ref={Button9Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/emoji9.gif")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/emoji9.gif" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>9</p1></span>
//         </a>

//         <a  ref={Button10Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/emoji0.gif")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/emoji0.gif" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>0</p1></span>
//         </a>
//       </div>
//         </div>
//       </div>
//       </div>
//     </div>
//   </div>
// </footer>




// </>
//   );

  
// };



// export default ChatScreen;



// new code with ads and audio message and custom emoji tv call

// import React, { useState, useEffect, useRef } from "react";
// import '../App.css';
// import axios from "axios";
// import Swal from 'sweetalert2';
// import AdBanner from './ads/AdBanner'

// const styles = {
//   textField: { width: "100%", borderWidth: 0, borderColor: "transparent" },
//   textFieldContainer: { flex: 1, marginRight: 12 },
//   gridItem: { paddingTop: 12, paddingBottom: 12 },
//   gridItemChatList: { overflow: "auto", height: "74vh", width: "-webkit-fill-available" },
//   gridItemMessage: { marginTop: 12, marginBottom: 12 },
//   sendButton: { backgroundColor: "transparent" },
//   sendIcon: { color: "white" },
//   mainGrid: { paddingTop: 100, borderWidth: 1 },
//   buttonContainer: {
//     position: 'fixed',
//     bottom: '8px',
//     left: 0,
//     right: 0,
//     display: 'grid',
//     justifyContent: 'center',
//     backgroundColor: 'transparent',
//   },
// };

// function extractSpecificUsername(email) {
//   var usernameRegex = /\/([^@\/]+)/;
//   var usernameMatch = email.match(usernameRegex);
//   return usernameMatch ? usernameMatch[1] : null;
// }

// function extractLastPathPart(url) {
//   const urlParts = url.split('/');
//   return urlParts[urlParts.length - 1];
// }

// function getStringBeforeAtSymbol(inputString) {
//   const atIndex = inputString.indexOf('@');
//   return atIndex === -1 ? inputString : inputString.substring(0, atIndex);
// }

// const ChatScreen = ({ username, socket }) => {
//   const [text, setText] = useState("");
//   const [messages, setMessages] = useState([]);
//   const [usernameid, setUsernameid] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [channel, setChannel] = useState(null);
//   const [floatingEmojis, setFloatingEmojis] = useState([]);
//   const [messageInput, setMessageInput] = useState('');
//   const [roomname, setRoomname] = useState('');
//   const [showMore, setShowMore] = useState(false);
//   const [additionalEmojis, setAdditionalEmojis] = useState([]);
//   const [isPaymentDone, setIsPaymentDone] = useState(false);

//   const [ProgramInfo, setProgramInfo] = useState(null);


//   //start
//   const [currentAudioIndex, setCurrentAudioIndex] = useState(null);
//   const audioRefs = useRef([]);

// //Voice Chat
//   const [recording, setRecording] = useState(false);
//   const [uploadedURL, setUploadedURL] = useState("");
//   const [audioBlob, setAudioBlob] = useState(null);
//   const mediaRecorderRef = useRef(null);
//   const audioChunks = useRef([]);

//   const recordTimeoutRef = useRef(null)

//   //Voice Chat
//   // Start Recording
//   // const startRecording = async () => {
//   //   const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//   //   mediaRecorderRef.current = new MediaRecorder(stream);

//   //   mediaRecorderRef.current.ondataavailable = (event) => {
//   //     audioChunks.current.push(event.data);
//   //   };

//   //   mediaRecorderRef.current.onstop = () => {
//   //     const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
//   //     console.log("audioBlob-->",audioBlob)
//   //     setAudioBlob(audioBlob);
//   //     uploadAudio(audioBlob); // Upload after recording is stopped
//   //   };

//   //   mediaRecorderRef.current.start();
//   //   setRecording(true);
//   // };

//   // // Stop Recording
//   // const stopRecording = () => {
//   //   mediaRecorderRef.current.stop();
//   //   setRecording(false);
//   //   audioChunks.current = []; // Clear the chunks after recording
//   // };

//   const startRecording = async () => {
//     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//     mediaRecorderRef.current = new MediaRecorder(stream);
  
//     mediaRecorderRef.current.ondataavailable = (event) => {
//       audioChunks.current.push(event.data);
//     };
  
//     mediaRecorderRef.current.onstop = () => {
//       const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
//       console.log("audioBlob-->", audioBlob);
//       setAudioBlob(audioBlob);
//       uploadAudio(audioBlob); // Upload after recording is stopped
//     };
  
//     mediaRecorderRef.current.start();
//     setRecording(true);
  
//     // Automatically stop recording after 10 seconds
//     recordTimeoutRef.current = setTimeout(() => {
//       stopRecording();
//     }, 10000); // 10000 ms = 10 seconds
//   };

//   const stopRecording = () => {
//     mediaRecorderRef.current.stop();
//     setRecording(false);
//     audioChunks.current = []; // Clear the chunks after recording
  
//     // Clear the timeout if the recording stops before 10 seconds
//     if (recordTimeoutRef.current) {
//       clearTimeout(recordTimeoutRef.current);
//     }
//   };
  

//   // Upload Audio
//   const uploadAudio = async (blob) => {
//     const formData = new FormData();
//     formData.append("audio", blob, "audio.wav");

//     try {
//       const response = await axios.post("https://watchparty.tpvai.com/b/audioupload", formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//       });
//       setUploadedURL(response.data.uploadedURL); // Use the returned URL to play the audio
//       sendMessage(response.data.uploadedURL)
//     } catch (error) {
//       console.error("Upload error:", error);
//     }
//   };

//   //Voice Chat

//   const scrollDiv = useRef(null);
//   const Button1Ref = useRef(null);
//   const Button2Ref = useRef(null);
//   const Button3Ref = useRef(null);
//   const Button4Ref = useRef(null);
//   const Button5Ref = useRef(null);
//   const Button6Ref = useRef(null);
//   const Button7Ref = useRef(null);
//   const Button8Ref = useRef(null);
//   const Button9Ref = useRef(null);
//   const Button10Ref = useRef(null);

//   const CurrentUser = localStorage.getItem("email");

//   useEffect(() => {
//     const storedEmail = localStorage.getItem('userId');
//     const storeProgramInfo = localStorage.getItem("ProgramInfo")
//     setProgramInfo(storeProgramInfo)
//     const username = storedEmail.split("@")[0];
//     setUsernameid(username);

//     const roomid = localStorage.getItem('roomid');
//     setRoomname(roomid);


//     //payment start
//     const paymentStatus = localStorage.getItem('paymentStatus');
//     if (paymentStatus === 'true') {
//       setIsPaymentDone(true);
//     }
//     //payment end

//     const handleKeyPress = (event) => {
//       if (event.key === "Enter" && currentAudioIndex !== null) {
//         const currentAudio = audioRefs.current[currentAudioIndex];
//         if (currentAudio) {
//           currentAudio.play();
//         }
//       }
//       if (event.key === "1") {
//         Button1Ref.current.click();
//       } else if (event.key === "2") {
//         Button2Ref.current.click();
//       } else if (event.key === "3") {
//         Button3Ref.current.click();
//       } else if (event.key === "4") {
//         Button4Ref.current.click();
//       } else if (event.key === "5") {
//         Button5Ref.current.click();
//       } else if (event.key === "6") {
//         Button6Ref.current.click();
//       } else if (event.key === "7") {
//         Button7Ref.current.click();
//       } else if (event.key === "8") {
//         Button8Ref.current.click();
//       } else if (event.key === "9") {
//         Button9Ref.current.click();
//       } else if (event.key === "0") {
//         Button10Ref.current.click();
//       }
//     };

//     window.addEventListener("keypress", handleKeyPress);

//     return () => {
//       window.removeEventListener("keypress", handleKeyPress);
//     };
//   }, []);

//   const showFloatingEmoji = (imageUrl) => {
//     if (imageUrl.endsWith('.png')) return; //not flying stickers
//     if (imageUrl.endsWith('.wav')) return;
//     const screenWidth = window.innerWidth;
//     const maxOffset = screenWidth / 4;
//     const offset = Math.random() * maxOffset * (Math.random() < 0.5 ? -1 : 1);
//     const container = document.createElement('div');
//     container.className = 'floating-emoji-container';
//     container.style.left = `calc(50% + ${offset}px)`;
//     container.style.transform = 'translateX(-50%)';
//     const floatingEmoji = document.createElement('img');
//     floatingEmoji.className = 'floating-emoji';
//     floatingEmoji.src = imageUrl;
//     floatingEmoji.alt = 'Emoji';
//     floatingEmoji.style.width = '60px';
//     floatingEmoji.style.height = '60px';
//     container.appendChild(floatingEmoji);
//     document.body.appendChild(container);
//     const expirationTime = Date.now() + 2000;
//     setFloatingEmojis([...floatingEmojis, { container, expirationTime }]);

//     const cardRect = document.querySelector('.card').getBoundingClientRect();
//     const containerRect = container.getBoundingClientRect();
//     const emojiRect = floatingEmoji.getBoundingClientRect();

//     if (containerRect.bottom > cardRect.top) {
//       const newTop = cardRect.top - emojiRect.height - 10;
//       container.style.top = `${newTop}px`;
//     }
//   };

//   useEffect(() => {
//     const handleMessage = (message) => {
//       const username = message.from;
//       showFloatingEmoji(message.message);
//       setMessages(prevMessages => [...prevMessages, message]);
//     };

//     socket.on("send-message-to-client", handleMessage);

//     return () => {
//       socket.off("send-message-to-client", handleMessage);
//     };
//   }, [socket]);

//   const sendMessage = (messageToSend) => {
//     if (messageToSend) {
//       socket.emit("receive-message-from-client", messageToSend);
//       setMessageInput('');
//     }
//   };


//   const handleMoreClick = async () => {
//     if (!isPaymentDone) {
//       // Use SweetAlert2 to create a custom modal
//       const { value: formValues } = await Swal.fire({
//         title: 'Payment Confirmation',
//         html: `
//           <div style="text-align: center;">
//             <label for="swal-input1">Amount: <strong>$30</strong></label>
//           </div>
//           <input id="swal-input1" class="swal2-input" placeholder="Enter your card number" type="text" 
//                  maxlength="16" pattern="\\d{16}" inputmode="numeric" style="margin-top: 15px;">
//           <input id="swal-input3" class="swal2-input" placeholder="MM/YY" type="text" 
//                  pattern="(0[1-9]|1[0-2])\\d{2}" maxlength="4" style="margin-top: 15px;">
//           <input id="swal-input2" class="swal2-input" placeholder="Enter CVV" type="text" 
//                  maxlength="3" pattern="\\d{3}" inputmode="numeric">
//         `,
//         focusConfirm: false,
//         showCancelButton: true,
//         confirmButtonText: 'Pay',
//         cancelButtonText: 'Cancel',
//         preConfirm: () => {
//           const cardNumber = document.getElementById('swal-input1').value;
//           const expiryDate = document.getElementById('swal-input3').value;
//           const cvv = document.getElementById('swal-input2').value;
  
//           // Basic validation for empty fields
//           if (!cardNumber || !expiryDate || !cvv) {
//             Swal.showValidationMessage('Please enter your card number, expiry date, and CVV');
//             return false;
//           }
  
//           return { cardNumber, expiryDate, cvv };
//         },
//         customClass: {
//           popup: 'custom-swal-popup',
//         },
//       });
  
//       if (formValues) {
//         // Simulate payment success after user submits details
//         localStorage.setItem('paymentStatus', 'true');
//         setIsPaymentDone(true);
//       }
//     } else {
//       setShowMore(!showMore); // Toggle the visibility of the card if payment is already done
//       if (!showMore && additionalEmojis.length === 0) {
//         try {
//           console.log("ytfy->",ProgramInfo)
//           console.log("ytfy-> type",typeof ProgramInfo)
//           let category = ProgramInfo;
//           // Remove any surrounding quotes from the category string, if present
//           if (typeof category === 'string') {
//             category = category.replace(/^"|"$/g, '');  // Removes leading and trailing quotes
//           }
//           const response = await fetch(`https://watchparty.tpvai.com/b/api/emoji/search?category=${category}`);
//           // const response = await fetch("https://watchparty.tpvai.com/b/search?category=tennis");
//           const data = await response.json();
//           console.log("shivans",data)
//           setAdditionalEmojis(data);
//         } catch (error) {
//           console.error("Failed to fetch additional emojis", error);
//         }
//       }
//     }
//   };
  

//   return (
//     <>
//       <div className="container-fluid">
//       <div style={{textAlign:'center'}}>
//       <AdBanner/>
//       </div>
//         <div>
//           <div className="col-6 chatitem">
//               {messages.map((msg, index) => {
//                 const userName = getStringBeforeAtSymbol(extractLastPathPart(msg.from));
//                 const alignStyle = CurrentUser === extractLastPathPart(msg.from) ? 'end' : 'left';
//                 const marginRStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
//                 const marginTStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
//                 const isGif = msg.message.endsWith('.gif');
//                 const isPng = msg.message.endsWith('.png');
//                 const isWav = msg.message.endsWith('.wav');

//                 if (isGif) {
//                   return null;
//                 }
//                 if (isPng) {
//                   return (
//                     <div key={index} style={{ textAlign: alignStyle, marginRight: marginRStyle }}>
//                       <img src={`https://watchparty.tpvai.com/b${msg.message}`} alt="png" width="75" height="75" />
//                       <span className="autherusername">{userName}</span>
//                     </div>
//                   );
//                 }
//                 // if (isWav) {
//                 //   return (
//                 //     <div key={index} style={{ textAlign: alignStyle, marginRight: marginRStyle }}>
//                 //       <audio controls src={msg.message} />
//                 //       <span className="autherusername">{userName}</span>
//                 //     </div>
//                 //   );
//                 // }
//                 if (isWav) {
//                   return (
//                     <div key={index} onClick={() => setCurrentAudioIndex(index)} style={{ textAlign: 'left' }}>
//                       <audio controls ref={(el) => (audioRefs.current[index] = el)} src={msg.message} />
//                       <span className="autherusername">{userName}</span>
//                     </div>
//                   );
//                 }
//               })}

//           </div>
//         </div>
//         <div className="row">
//           <div className="col-6 text-sm-end">
//           </div>
//         </div>
//       </div>

//       <footer className="fixed-bottom p-3">
//         <div className="container-fluid">
//           <div className="row justify-content-center">
//             <div className="col-auto">
//               <div className="card">
//                 <div className="card-body">
//                   <div>
//                   <a  ref={Button1Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/sticker1.png")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/sticker1.png" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>1</p1></span>
//         </a>

//         <a  ref={Button2Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/sticker2.png")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/sticker2.png" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>2</p1></span>
//         </a>

//         <a  ref={Button3Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/sticker3.png")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/sticker3.png" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>3</p1></span>
//         </a>

//         <a  ref={Button4Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/sticker4.png")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/sticker4.png" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>4</p1></span>
//         </a>

//         <a  ref={Button5Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/sticker6.png")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/sticker6.png" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>5</p1></span>
//         </a>
//                 </div>
//                  <br/>

//                 <div>
//         <a  ref={Button6Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/emoji6.gif")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/emoji6.gif" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>6</p1></span>
//         </a>

//         <a  ref={Button7Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/emoji7.gif")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/emoji7.gif" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>7</p1></span>
//         </a>

//         <a  ref={Button8Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/emoji8.gif")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/emoji8.gif" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>8</p1></span>
//         </a>

//         <a  ref={Button9Ref} onClick={() => sendMessage("https://watchparty.tpvai.com/b/uploads/emoji9.gif")} style={styles.sendButton} disabled={!channel}>
//           <img src="https://watchparty.tpvai.com/b/uploads/emoji9.gif" alt="Description of the image" width="30" height="30"/>
//           <span style={{position: 'relative', top: '23px', right: '17px', fontSize: '10px'}}><p1>9</p1></span>
//         </a>

        
        

          
//                   </div>



//                   <div className="row">
//                     <div className="col-auto">
//                       {showMore && (
//                         <div className="card">
//                           <div className="card-body">
//                             {additionalEmojis.map((emoji, index) => (
//                               <a key={index} onClick={() => sendMessage(emoji.url)} style={styles.sendButton} disabled={!channel}>
//                                 <img src={`https://watchparty.tpvai.com/b${emoji.url}`} alt="emoji" width="30" height="30" />
//                               </a>
//                             ))}
//                           </div>
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                   <div>
//                     <br/>
//                   {/* <div onClick={recording ? stopRecording : startRecording}>
//                     {recording ? (
//                       <img src="https://watchparty.tpvai.com/b/uploads/stop.gif" alt="Stop Recording" />
//                     ) : (
//                       <img src="https://watchparty.tpvai.com/b/uploads/start.png" alt="Start Recording" />
//                     )}
//                   </div> */}

//                 <div 
//                   tabIndex={0}  // Make the div focusable
//                   onClick={recording ? stopRecording : startRecording} 
//                   onKeyDown={(e) => {
//                     if (e.key === "Enter" || e.key === " ") {  // Trigger on Enter or Space key
//                       recording ? stopRecording() : startRecording();
//                     }
//                   }}
//                   role="button"  // Add role for better accessibility
//                   style={{ cursor: 'pointer' }}  // Add pointer cursor to indicate clickability
//                 >
//                   {recording ? (
//                     <img src="https://watchparty.tpvai.com/b/uploads/stop.gif" alt="Stop Recording" />
//                   ) : (
//                     <img src="https://watchparty.tpvai.com/b/uploads/start.png" alt="Start Recording" />
//                   )}
//                 </div>


//                   <br/>

//                   <a ref={Button10Ref} onClick={handleMoreClick} style={styles.sendButton} disabled={!channel}>
//                     <span className="morecard">{isPaymentDone ? "Custom" : "Premium"}</span>
//                   </a>
//                   <br/>
//                   </div>
//                 </div>
//               </div>

//             </div>
//           </div>
//         </div>
//       </footer>
//     </>
//   );
// };

// export default ChatScreen;




//ex

import React, { useState, useEffect, useRef, useCallback } from "react";
import '../App.css';
import axios from "axios";
import Swal from 'sweetalert2';
import AdBanner from './ads/AdBanner'

const styles = {
  textField: { width: "100%", borderWidth: 0, borderColor: "transparent" },
  textFieldContainer: { flex: 1, marginRight: 12 },
  gridItem: { paddingTop: 12, paddingBottom: 12 },
  gridItemChatList: { overflow: "auto", height: "74vh", width: "-webkit-fill-available" },
  gridItemMessage: { marginTop: 12, marginBottom: 12 },
  sendButton: { backgroundColor: "transparent" },
  sendIcon: { color: "white" },
  mainGrid: { paddingTop: 100, borderWidth: 1 },
  buttonContainer: {
    position: 'fixed',
    bottom: '8px',
    left: 0,
    right: 0,
    display: 'grid',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
};

function extractSpecificUsername(email) {
  var usernameRegex = /\/([^@\/]+)/;
  var usernameMatch = email.match(usernameRegex);
  return usernameMatch ? usernameMatch[1] : null;
}

function extractLastPathPart(url) {
  const urlParts = url.split('/');
  return urlParts[urlParts.length - 1];
}

function getStringBeforeAtSymbol(inputString) {
  const atIndex = inputString.indexOf('@');
  return atIndex === -1 ? inputString : inputString.substring(0, atIndex);
}

const ChatScreen = ({ username, socket }) => {
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  const [usernameid, setUsernameid] = useState([]);
  const [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState(null);
  const [floatingEmojis, setFloatingEmojis] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [roomname, setRoomname] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [additionalEmojis, setAdditionalEmojis] = useState([]);
  const [isPaymentDone, setIsPaymentDone] = useState(false);

  const [ProgramInfo, setProgramInfo] = useState(null);


  //start
  const [currentAudioIndex, setCurrentAudioIndex] = useState(null);
  const audioRefs = useRef([]);

//Voice Chat
  const [recording, setRecording] = useState(false);
  const [uploadedURL, setUploadedURL] = useState("");
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);



    //new changes for ui
    const [emojis, setEmojis] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [clickedImage, setClickedImage] = useState(null);
    const imagesPerPage = 6;
  
    const emojiRefs = useRef([]);


  const recordTimeoutRef = useRef(null)

  //Voice Chat
  // Start Recording
  // const startRecording = async () => {
  //   const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //   mediaRecorderRef.current = new MediaRecorder(stream);

  //   mediaRecorderRef.current.ondataavailable = (event) => {
  //     audioChunks.current.push(event.data);
  //   };

  //   mediaRecorderRef.current.onstop = () => {
  //     const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
  //     console.log("audioBlob-->",audioBlob)
  //     setAudioBlob(audioBlob);
  //     uploadAudio(audioBlob); // Upload after recording is stopped
  //   };

  //   mediaRecorderRef.current.start();
  //   setRecording(true);
  // };

  // // Stop Recording
  // const stopRecording = () => {
  //   mediaRecorderRef.current.stop();
  //   setRecording(false);
  //   audioChunks.current = []; // Clear the chunks after recording
  // };

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
  
    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunks.current.push(event.data);
    };
  
    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
      console.log("audioBlob-->", audioBlob);
      setAudioBlob(audioBlob);
      uploadAudio(audioBlob); // Upload after recording is stopped
    };
  
    mediaRecorderRef.current.start();
    setRecording(true);
  
    // Automatically stop recording after 10 seconds
    recordTimeoutRef.current = setTimeout(() => {
      stopRecording();
    }, 10000); // 10000 ms = 10 seconds
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
    audioChunks.current = []; // Clear the chunks after recording
  
    // Clear the timeout if the recording stops before 10 seconds
    if (recordTimeoutRef.current) {
      clearTimeout(recordTimeoutRef.current);
    }
  };
  

  // Upload Audio
  const uploadAudio = async (blob) => {
    const formData = new FormData();
    formData.append("audio", blob, "audio.wav");

    try {
      const response = await axios.post("https://watchparty.tpvai.com/b/audioupload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setUploadedURL(response.data.uploadedURL); // Use the returned URL to play the audio
      sendMessage(response.data.uploadedURL)
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  //Voice Chat

  const scrollDiv = useRef(null);
  const Button1Ref = useRef(null);
  const Button2Ref = useRef(null);
  const Button3Ref = useRef(null);
  const Button4Ref = useRef(null);
  const Button5Ref = useRef(null);
  const Button6Ref = useRef(null);
  const Button7Ref = useRef(null);
  const Button8Ref = useRef(null);
  const Button9Ref = useRef(null);
  const Button10Ref = useRef(null);

  const CurrentUser = localStorage.getItem("email");

  useEffect(() => {
    const storedEmail = localStorage.getItem('userId');
    const username = storedEmail.split("@")[0];
    setUsernameid(username);

    const roomid = localStorage.getItem('roomid');
    setRoomname(roomid);


    //payment start
    const paymentStatus = localStorage.getItem('paymentStatus');
    if (paymentStatus === 'true') {
      setIsPaymentDone(true);
    }
    //payment end


    // api call
    const fetchEmojis = async () => {
      try {
        setLoading(true);
        const storeProgramInfo = localStorage.getItem("ProgramInfo")
        let category = storeProgramInfo;
        // Remove any surrounding quotes from the category string, if present
        if (typeof category === 'string') {
          category = category.replace(/^"|"$/g, '');  // Removes leading and trailing quotes
        }
    
        console.log("sahu2new",category)
        setProgramInfo(storeProgramInfo)
        const response = await axios.get(
          // "https://watchparty.tpvai.com/b/api/emoji/search?category=GENRE_CHILDREN"
          `https://watchparty.tpvai.com/b/api/emoji/search?category=${category}`
        );
        setEmojis(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching emojis", error);
        setLoading(false);
      }
    };

    fetchEmojis();
    
    const handleKeyPress = (event) => {
      // if (event.key === "Enter" && currentAudioIndex !== null) {
      //   const currentAudio = audioRefs.current[currentAudioIndex];
      //   if (currentAudio) {
      //     currentAudio.play();
      //   }
      // }

      // if (event.key === "1") {
      //   Button1Ref.current.click();
      // } else if (event.key === "2") {
      //   Button2Ref.current.click();
      // } else if (event.key === "3") {
      //   Button3Ref.current.click();
      // } else if (event.key === "4") {
      //   Button4Ref.current.click();
      // } else if (event.key === "5") {
      //   Button5Ref.current.click();
      // } else if (event.key === "6") {
      //   Button6Ref.current.click();
      // } else if (event.key === "7") {
      //   Button7Ref.current.click();
      // } else if (event.key === "8") {
      //   Button8Ref.current.click();
      // } else if (event.key === "9") {
      //   Button9Ref.current.click();
      // } else if (event.key === "0") {
      //   Button10Ref.current.click();
      // }
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);






  

    //new changes ui
    const handleNext = useCallback(() => {
      if (startIndex + imagesPerPage < emojis.length) {
        setStartIndex((prevIndex) => prevIndex + imagesPerPage);
      }
    }, [startIndex, emojis.length]);
  
    const handlePrevious = useCallback(() => {
      if (startIndex - imagesPerPage >= 0) {
        setStartIndex((prevIndex) => prevIndex - imagesPerPage);
      }
    }, [startIndex]);
  
    const handleImageClick = (index,emoji) => {
      setClickedImage(index + 1);
      console.log("emojis-new-audio-4",emoji.url)
      const url =  `https://watchparty.tpvai.com/b${emoji.url}`
      if (emoji.paid && !isPaymentDone) {
        handlePaymentConfirmation();
      } else {
        sendMessage(url)
      }
    };
  
  console.log("anu recording",recording)
    useEffect(() => {
      const handleKeyPress = (event) => {
        const key = event.key;
  
        if (key >= "1" && key <= "6") {
          const index = parseInt(key) - 1;
          if (emojiRefs.current[index]) {
            console.log("emojis-new-audio-2",clickedImage)
            emojiRefs.current[index].click();
          }
        // } else if (key === "ArrowRight") {
        } else if (key === "0") {
          handleNext();
        } 
        // else if (key === "9") {
        //   console.log("anu 9")
        //   recording ? stopRecording() : startRecording();
        // }
        else if (key === "8") {
          handlePrevious();
        }
      };
  
      window.addEventListener("keydown", handleKeyPress);
  
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }, [handleNext, handlePrevious]);
  

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === "9") {
          if (recording) {
            stopRecording();
          } else {
            startRecording();
          }
        }
      };
  
      window.addEventListener("keydown", handleKeyDown);
  
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [recording]);


  
    console.log("emojis-new-audio-3",clickedImage)
  

  const currentEmojis = emojis.slice(startIndex, startIndex + imagesPerPage);


  const handlePaymentConfirmation = async () => {
    const { value: formValues } = await Swal.fire({
      title: 'Payment Confirmation',
      html: `
        <div style="text-align: center;">
          <label for="swal-input1">Amount: <strong>$30</strong></label>
        </div>
        <input id="swal-input1" class="swal2-input" placeholder="Enter your card number" type="text" 
               maxlength="16" pattern="\\d{16}" inputmode="numeric" style="margin-top: 15px;">
        <input id="swal-input3" class="swal2-input" placeholder="MM/YY" type="text" 
               pattern="(0[1-9]|1[0-2])\\d{2}" maxlength="4" style="margin-top: 15px;">
        <input id="swal-input2" class="swal2-input" placeholder="Enter CVV" type="text" 
               maxlength="3" pattern="\\d{3}" inputmode="numeric">
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Pay',
      cancelButtonText: 'Cancel',
      preConfirm: () => {
        const cardNumber = document.getElementById('swal-input1').value;
        const expiryDate = document.getElementById('swal-input3').value;
        const cvv = document.getElementById('swal-input2').value;
  
        if (!cardNumber || !expiryDate || !cvv) {
          Swal.showValidationMessage('Please enter your card number, expiry date, and CVV');
          return false;
        }
        return { cardNumber, expiryDate, cvv };
      },
      customClass: {
        popup: 'custom-swal-popup',
      },
    });
  
    if (formValues) {
      // Simulate payment success after user submits details
      localStorage.setItem('paymentStatus', 'true');
      setIsPaymentDone(true);
    }
  };
  
  const showFloatingEmoji = (imageUrl) => {
    if (imageUrl.endsWith('.png')) return; //not flying stickers
    if (imageUrl.endsWith('.wav')) return;
    const screenWidth = window.innerWidth;
    const maxOffset = screenWidth / 4;
    const offset = Math.random() * maxOffset * (Math.random() < 0.5 ? -1 : 1);
    const container = document.createElement('div');
    container.className = 'floating-emoji-container';
    container.style.left = `calc(50% + ${offset}px)`;
    container.style.transform = 'translateX(-50%)';
    const floatingEmoji = document.createElement('img');
    floatingEmoji.className = 'floating-emoji';
    floatingEmoji.src = imageUrl;
    floatingEmoji.alt = 'Emoji';
    floatingEmoji.style.width = '60px';
    floatingEmoji.style.height = '60px';
    container.appendChild(floatingEmoji);
    document.body.appendChild(container);
    const expirationTime = Date.now() + 2000;
    setFloatingEmojis([...floatingEmojis, { container, expirationTime }]);

    const cardRect = document.querySelector('.card').getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    const emojiRect = floatingEmoji.getBoundingClientRect();

    if (containerRect.bottom > cardRect.top) {
      const newTop = cardRect.top - emojiRect.height - 10;
      container.style.top = `${newTop}px`;
    }
  };

  // useEffect(() => {
  //   const handleMessage = (message) => {
  //     const username = message.from;
  //     showFloatingEmoji(message.message);
  //     setMessages(prevMessages => [...prevMessages, message]);
  //   };

  //   socket.on("send-message-to-client", handleMessage);

  //   return () => {
  //     socket.off("send-message-to-client", handleMessage);
  //   };
  // }, [socket]);
  useEffect(() => {
    const handleMessage = (message) => {
      const username = message.from;
      const isWav = message.message.endsWith('.wav');
  
      showFloatingEmoji(message.message);
      setMessages((prevMessages) => [...prevMessages, message]);
      console.log("anu play -1")
      // Automatically play audio if the message is a .wav file
      if (isWav) {
        console.log("anu play 0")
        setTimeout(() => {
          console.log("anu play 1")
          const newIndex = messages.length; // Index of the new message
          setCurrentAudioIndex(newIndex); // Store index to control the current audio
          audioRefs.current[newIndex].play(); // Auto-play the new audio
        }, 100); // Delay to ensure audio ref is set
      }
    };
  
    socket.on("send-message-to-client", handleMessage);
  
    return () => {
      socket.off("send-message-to-client", handleMessage);
    };
  }, [messages, socket]);
  

  const sendMessage = (messageToSend) => {
    if (messageToSend) {
      socket.emit("receive-message-from-client", messageToSend);
      setMessageInput('');
    }
  };


  const handleMoreClick = async () => {
    if (!isPaymentDone) {
      // Use SweetAlert2 to create a custom modal
      const { value: formValues } = await Swal.fire({
        title: 'Payment Confirmation',
        html: `
          <div style="text-align: center;">
            <label for="swal-input1">Amount: <strong>$30</strong></label>
          </div>
          <input id="swal-input1" class="swal2-input" placeholder="Enter your card number" type="text" 
                 maxlength="16" pattern="\\d{16}" inputmode="numeric" style="margin-top: 15px;">
          <input id="swal-input3" class="swal2-input" placeholder="MM/YY" type="text" 
                 pattern="(0[1-9]|1[0-2])\\d{2}" maxlength="4" style="margin-top: 15px;">
          <input id="swal-input2" class="swal2-input" placeholder="Enter CVV" type="text" 
                 maxlength="3" pattern="\\d{3}" inputmode="numeric">
        `,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Pay',
        cancelButtonText: 'Cancel',
        preConfirm: () => {
          const cardNumber = document.getElementById('swal-input1').value;
          const expiryDate = document.getElementById('swal-input3').value;
          const cvv = document.getElementById('swal-input2').value;
  
          // Basic validation for empty fields
          if (!cardNumber || !expiryDate || !cvv) {
            Swal.showValidationMessage('Please enter your card number, expiry date, and CVV');
            return false;
          }
  
          return { cardNumber, expiryDate, cvv };
        },
        customClass: {
          popup: 'custom-swal-popup',
        },
      });
  
      if (formValues) {
        // Simulate payment success after user submits details
        localStorage.setItem('paymentStatus', 'true');
        setIsPaymentDone(true);
      }
    } else {
      setShowMore(!showMore); // Toggle the visibility of the card if payment is already done
      if (!showMore && additionalEmojis.length === 0) {
        try {
          console.log("ytfy->",ProgramInfo)
          console.log("ytfy-> type",typeof ProgramInfo)
          let category = ProgramInfo;
          // Remove any surrounding quotes from the category string, if present
          if (typeof category === 'string') {
            category = category.replace(/^"|"$/g, '');  // Removes leading and trailing quotes
          }
          const response = await fetch(`https://watchparty.tpvai.com/b/api/emoji/search?category=${category}`);
          // const response = await fetch("https://watchparty.tpvai.com/b/search?category=tennis");
          const data = await response.json();
          console.log("shivans",data)
          setAdditionalEmojis(data);
        } catch (error) {
          console.error("Failed to fetch additional emojis", error);
        }
      }
    }
  };
  

  return (
    <>
      <div className="container-fluid">
      <div style={{textAlign:'center'}}>
      <AdBanner/>
      </div>
        <div>
          <div className="col-6 chatitem">
              {messages.map((msg, index) => {
                const userName = getStringBeforeAtSymbol(extractLastPathPart(msg.from));
                const alignStyle = CurrentUser === extractLastPathPart(msg.from) ? 'end' : 'left';
                const marginRStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
                const marginTStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
                const isGif = msg.message.endsWith('.gif');
                const isPng = msg.message.endsWith('.png');
                const isWav = msg.message.endsWith('.wav');

                if (isGif) {
                  return null;
                }
                // if (isPng) {
                //   return (
                //     <div key={index} style={{ textAlign: alignStyle, marginRight: marginRStyle }}>
                //       <img src={`https://watchparty.tpvai.com/b${msg.message}`} alt="png" width="75" height="75" />
                //       <span className="autherusername">{userName}</span>
                //     </div>
                //   );
                // }
                if (isPng) {
                  return (
                    <div key={index} style={{ textAlign: alignStyle, marginRight: marginRStyle }}>
                      <img src={`https://watchparty.tpvai.com/b${msg.message}`} alt="png" width="75" height="75" />
                      <span className="autherusername">{userName}</span>
                    </div>
                  );
                }
                
                
                // if (isWav) {
                //   return (
                //     <div key={index} style={{ textAlign: alignStyle, marginRight: marginRStyle }}>
                //       <audio controls src={msg.message} />
                //       <span className="autherusername">{userName}</span>
                //     </div>
                //   );
                // }
                if (isWav) {
                  return (
                    <div key={index} onClick={() => setCurrentAudioIndex(index)} style={{ textAlign: 'left' }}>
                       <audio controls ref={(el) => (audioRefs.current[index] = el)} src={msg.message} />
                      <span className="autherusername">{userName}</span>
                    </div>
                  );
                }
              })}

          </div>
        </div>
        <div className="row">
          <div className="col-6 text-sm-end">
          </div>
        </div>
      </div>

      <footer className="fixed-bottom p-3">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="card">


                <div className="container text-center mt-4" id="emoji-gallery" tabIndex="0" style={{ margin: 0, padding: 0 }}>
  {loading ? (
    <div className="spinner-border" role="status" style={{margin: '27px 82px'}}>
      <span className="visually-hidden">Loading...</span>
    </div>
  ) : (
    <>
      <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
        {currentEmojis.map((emoji, index) => (
          <div
            key={index}
            className="col-4 d-flex flex-column align-items-center position-relative px-1" // Added px-1 for spacing
            ref={(el) => (emojiRefs.current[index] = el)}
            onClick={() => handleImageClick(index, emoji)}
            style={{ margin: 0, padding: 0 }}
          >
            {emoji.paid && (
              <span style={{position: 'absolute', backgroundColor: '#dfb400', color: '#000', width: '21px', height: '21px', borderRadius: '50%', display: 'inline-block', textAlign: 'center', marginLeft:'47px'}}>
                P
              </span>
            )}
            <img
              src={`https://watchparty.tpvai.com/b${emoji.url}`}
              alt="emoji"
              className="img-fluid"
              style={{
                width: "23px",
                height: "23px",
                objectFit: "cover",
              }}
            />
            <span className="">{index + 1}</span>
          </div>
        ))}
      </div>

      {/* {clickedImage !== null && (
        <div className="alert alert-info" style={{ marginTop: '5px', marginBottom: '5px' }}>
          You clicked on image {clickedImage}
        </div>
      )} */}

      <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
        <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
          <div style={{ cursor: "pointer" }} onClick={handlePrevious}>
            <img
              src="https://cdn-icons-png.flaticon.com/512/608/608283.png"
              alt="previous"
              className="img-fluid"
              style={{
                width: "23px",
                height: "23px",
                objectFit: "cover",
              }}
            />
            <p className="mt-2">8</p>
          </div>
        </div>
        <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
          <div style={{ cursor: "pointer" }}>
            {/* <img
              src="https://watchparty.tpvai.com/b/uploads/start.png"
              alt="start"
              className="img-fluid"
              style={{
                width: "23px",
                height: "23px",
                objectFit: "cover",
              }}
            />
            <p className="mt-2">9</p> */}
            <div 
                  tabIndex={0}  // Make the div focusable
                  onClick={recording ? stopRecording : startRecording} 
                  role="button"  // Add role for better accessibility
                  style={{ cursor: 'pointer' }}  // Add pointer cursor to indicate clickability
                >
                  {recording ? (
                    <img src="https://watchparty.tpvai.com/b/uploads/stop.gif" alt="Stop Recording" />
                  ) : (
                    <img src="https://watchparty.tpvai.com/b/uploads/start.png" alt="Start Recording" />
                  )}
                  <p className="mt-2">9</p>
                </div>

          </div>
        </div>
        <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
          <div style={{ cursor: "pointer" }} onClick={handleNext}>
            <img
              src="https://cdn-icons-png.flaticon.com/512/626/626043.png"
              alt="next"
              className="img-fluid"
              style={{
                width: "23px",
                height: "23px",
                objectFit: "cover",
              }}
            />
            <p className="mt-2">0</p>
          </div>
        </div>
      </div>
    </>
  )}
      </div>

              </div>

            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ChatScreen;

//ex



// new changes with new ui and payment and custome emoji

// import React, { useState, useEffect, useRef, useCallback } from "react";
// import '../App.css';
// import axios from "axios";
// import Swal from 'sweetalert2';
// import AdBanner from './ads/AdBanner'

// const styles = {
//   textField: { width: "100%", borderWidth: 0, borderColor: "transparent" },
//   textFieldContainer: { flex: 1, marginRight: 12 },
//   gridItem: { paddingTop: 12, paddingBottom: 12 },
//   gridItemChatList: { overflow: "auto", height: "74vh", width: "-webkit-fill-available" },
//   gridItemMessage: { marginTop: 12, marginBottom: 12 },
//   sendButton: { backgroundColor: "transparent" },
//   sendIcon: { color: "white" },
//   mainGrid: { paddingTop: 100, borderWidth: 1 },
//   buttonContainer: {
//     position: 'fixed',
//     bottom: '8px',
//     left: 0,
//     right: 0,
//     display: 'grid',
//     justifyContent: 'center',
//     backgroundColor: 'transparent',
//   },
// };

// function extractSpecificUsername(email) {
//   var usernameRegex = /\/([^@\/]+)/;
//   var usernameMatch = email.match(usernameRegex);
//   return usernameMatch ? usernameMatch[1] : null;
// }

// function extractLastPathPart(url) {
//   const urlParts = url.split('/');
//   return urlParts[urlParts.length - 1];
// }

// function getStringBeforeAtSymbol(inputString) {
//   const atIndex = inputString.indexOf('@');
//   return atIndex === -1 ? inputString : inputString.substring(0, atIndex);
// }

// const ChatScreen = ({ username, socket }) => {
//   const [text, setText] = useState("");
//   const [messages, setMessages] = useState([]);
//   const [usernameid, setUsernameid] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [channel, setChannel] = useState(null);
//   const [floatingEmojis, setFloatingEmojis] = useState([]);
//   const [messageInput, setMessageInput] = useState('');
//   const [roomname, setRoomname] = useState('');
//   const [showMore, setShowMore] = useState(false);
//   const [additionalEmojis, setAdditionalEmojis] = useState([]);
//   const [isPaymentDone, setIsPaymentDone] = useState(false);

//   const [ProgramInfo, setProgramInfo] = useState();


//   //start
//   const [currentAudioIndex, setCurrentAudioIndex] = useState(null);
//   const audioRefs = useRef([]);

// //Voice Chat
//   const [recording, setRecording] = useState(false);
//   const [uploadedURL, setUploadedURL] = useState("");
//   const [audioBlob, setAudioBlob] = useState(null);
//   const mediaRecorderRef = useRef(null);
//   const audioChunks = useRef([]);


//     //new changes for ui
//     const [emojis, setEmojis] = useState([]);
//     const [startIndex, setStartIndex] = useState(0);
//     const [clickedImage, setClickedImage] = useState(null);
//     const imagesPerPage = 6;
  
//   const emojiRefs = useRef([]);
//   const recordTimeoutRef = useRef(null)


//   const startRecording = async () => {
//     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//     mediaRecorderRef.current = new MediaRecorder(stream);
  
//     mediaRecorderRef.current.ondataavailable = (event) => {
//       audioChunks.current.push(event.data);
//     };
  
//     mediaRecorderRef.current.onstop = () => {
//       const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
//       console.log("audioBlob-->", audioBlob);
//       setAudioBlob(audioBlob);
//       uploadAudio(audioBlob); // Upload after recording is stopped
//     };
  
//     mediaRecorderRef.current.start();
//     setRecording(true);
  
//     // Automatically stop recording after 10 seconds
//     recordTimeoutRef.current = setTimeout(() => {
//       stopRecording();
//     }, 10000); // 10000 ms = 10 seconds
//   };

//   const stopRecording = () => {
//     mediaRecorderRef.current.stop();
//     setRecording(false);
//     audioChunks.current = []; // Clear the chunks after recording
  
//     // Clear the timeout if the recording stops before 10 seconds
//     if (recordTimeoutRef.current) {
//       clearTimeout(recordTimeoutRef.current);
//     }
//   };
  

//   // Upload Audio
//   const uploadAudio = async (blob) => {
//     const formData = new FormData();
//     formData.append("audio", blob, "audio.wav");

//     try {
//       const response = await axios.post("https://watchparty.tpvai.com/b/audioupload", formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//       });
//       setUploadedURL(response.data.uploadedURL); // Use the returned URL to play the audio
//       sendMessage(response.data.uploadedURL)
//     } catch (error) {
//       console.error("Upload error:", error);
//     }
//   };

//   //Voice Chat


//   const CurrentUser = localStorage.getItem("email");

//   useEffect(() => {
//     const storedEmail = localStorage.getItem('userId');
//     const storeProgramInfo = localStorage.getItem("ProgramInfo")
//     setProgramInfo(storeProgramInfo)
//     const username = storedEmail.split("@")[0];
//     setUsernameid(username);

//     const roomid = localStorage.getItem('roomid');
//     setRoomname(roomid);



//     const fetchEmojis = async () => {
//       // try {
//       //   setLoading(true);
//       //   console.log("ytfy->",ProgramInfo)
//       //   console.log("ytfy-> type",typeof ProgramInfo)
//       //   let category = ProgramInfo;
//       //   if (typeof category === 'string') {
//       //     category = category.replace(/^"|"$/g, '');  // Removes leading and trailing quotes
//       //     console.log("categorycategory->",`https://watchparty.tpvai.com/b/api/emoji/search?category=${category}`)
//       //   }
//       //   const response = await axios.get(
//       //     // "https://watchparty.tpvai.com/b/api/emoji/search?category=GENRE_MOVIE"
//       //     `https://watchparty.tpvai.com/b/api/emoji/search?category=${category}`
//       //   );
//       //   setEmojis(response.data);
//       //   setLoading(false);
//       // } catch (error) {
//       //   console.error("Error fetching emojis", error);
//       //   setLoading(false);
//       // }


//       setShowMore(!showMore); // Toggle the visibility of the card if payment is already done
//       if (!showMore && additionalEmojis.length === 0) {
//         try {
//           console.log("ytfy->",ProgramInfo)
//           console.log("ytfy-> type",typeof ProgramInfo)
//           let category = ProgramInfo;
//           // Remove any surrounding quotes from the category string, if present
//           if (typeof category === 'string') {
//             category = category.replace(/^"|"$/g, '');  // Removes leading and trailing quotes
//           }
//           const response = await fetch(`https://watchparty.tpvai.com/b/api/emoji/search?category=${category}`);
//           // const response = await fetch("https://watchparty.tpvai.com/b/search?category=tennis");
//           const data = await response.json();
//           console.log("shivans",data)
//           setAdditionalEmojis(data);
//           setEmojis(data);
//         } catch (error) {
//           console.error("Failed to fetch additional emojis", error);
//         }
//       }
//     };

//     fetchEmojis();

    

//     //payment start
//     const paymentStatus = localStorage.getItem('paymentStatus');
//     if (paymentStatus === 'true') {
//       setIsPaymentDone(true);
//     }
//     //payment end

//     // const handleKeyPress = (event) => {
//     //   if (event.key === "Enter" && currentAudioIndex !== null) {
//     //     const currentAudio = audioRefs.current[currentAudioIndex];
//     //     if (currentAudio) {
//     //       currentAudio.play();
//     //     }
//     //   }
//     //   if (event.key === "1") {
//     //     Button1Ref.current.click();
//     //   } else if (event.key === "2") {
//     //     Button2Ref.current.click();
//     //   } else if (event.key === "3") {
//     //     Button3Ref.current.click();
//     //   } else if (event.key === "4") {
//     //     Button4Ref.current.click();
//     //   } else if (event.key === "5") {
//     //     Button5Ref.current.click();
//     //   } else if (event.key === "6") {
//     //     Button6Ref.current.click();
//     //   } else if (event.key === "7") {
//     //     Button7Ref.current.click();
//     //   } else if (event.key === "8") {
//     //     Button8Ref.current.click();
//     //   } else if (event.key === "9") {
//     //     Button9Ref.current.click();
//     //   } else if (event.key === "0") {
//     //     Button10Ref.current.click();
//     //   }
//     // };

//     // window.addEventListener("keypress", handleKeyPress);

//     // return () => {
//     //   window.removeEventListener("keypress", handleKeyPress);
//     // };
//   }, []);


//   //new changes ui
//   const handleNext = useCallback(() => {
//     if (startIndex + imagesPerPage < emojis.length) {
//       setStartIndex((prevIndex) => prevIndex + imagesPerPage);
//     }
//   }, [startIndex, emojis.length]);

//   const handlePrevious = useCallback(() => {
//     if (startIndex - imagesPerPage >= 0) {
//       setStartIndex((prevIndex) => prevIndex - imagesPerPage);
//     }
//   }, [startIndex]);

//   const handleImageClick = (index,emoji) => {
//     setClickedImage(index + 1);
//     console.log("emojis-new-audio-4",emoji.url)
//     const url =  `https://watchparty.tpvai.com/b${emoji.url}`
//     if (emoji.paid && !isPaymentDone) {
//       handlePaymentConfirmation();
//     } else {
//       sendMessage(url)
//     }
//   };
//   useEffect(() => {
//     const handleKeyPress = (event) => {
//       const key = event.key;

//       if (key >= "1" && key <= "6") {
//         const index = parseInt(key) - 1;
//         if (emojiRefs.current[index]) {
//           console.log("emojis-new-audio-2",clickedImage)
//           emojiRefs.current[index].click();
//         }
//       // } else if (key === "ArrowRight") {
//       } else if (key === "0") {
//         handleNext();
//       } else if (key === "8") {
//         handlePrevious();
//       }
//     };

//     window.addEventListener("keydown", handleKeyPress);

//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, [handleNext, handlePrevious]);

//   console.log("emojis-new-audio-3",clickedImage)

//   const currentEmojis = emojis.slice(startIndex, startIndex + imagesPerPage);


//   const showFloatingEmoji = (imageUrl) => {
//     if (imageUrl.endsWith('.png')) return; //not flying stickers
//     if (imageUrl.endsWith('.wav')) return;
//     const screenWidth = window.innerWidth;
//     const maxOffset = screenWidth / 4;
//     const offset = Math.random() * maxOffset * (Math.random() < 0.5 ? -1 : 1);
//     const container = document.createElement('div');
//     container.className = 'floating-emoji-container';
//     container.style.left = `calc(50% + ${offset}px)`;
//     container.style.transform = 'translateX(-50%)';
//     const floatingEmoji = document.createElement('img');
//     floatingEmoji.className = 'floating-emoji';
//     floatingEmoji.src = imageUrl;
//     floatingEmoji.alt = 'Emoji';
//     floatingEmoji.style.width = '60px';
//     floatingEmoji.style.height = '60px';
//     container.appendChild(floatingEmoji);
//     document.body.appendChild(container);
//     const expirationTime = Date.now() + 2000;
//     setFloatingEmojis([...floatingEmojis, { container, expirationTime }]);

//     const cardRect = document.querySelector('.card').getBoundingClientRect();
//     const containerRect = container.getBoundingClientRect();
//     const emojiRect = floatingEmoji.getBoundingClientRect();

//     if (containerRect.bottom > cardRect.top) {
//       const newTop = cardRect.top - emojiRect.height - 10;
//       container.style.top = `${newTop}px`;
//     }
//   };

//   useEffect(() => {
//     const handleMessage = (message) => {
//       const username = message.from;
//       showFloatingEmoji(message.message);
//       setMessages(prevMessages => [...prevMessages, message]);
//     };

//     socket.on("send-message-to-client", handleMessage);

//     return () => {
//       socket.off("send-message-to-client", handleMessage);
//     };
//   }, [socket]);

//   const sendMessage = (messageToSend) => {
//     if (messageToSend) {
//       socket.emit("receive-message-from-client", messageToSend);
//       setMessageInput('');
//     }
//   };

//   const handlePaymentConfirmation = async () => {
//     const { value: formValues } = await Swal.fire({
//       title: 'Payment Confirmation',
//       html: `
//         <div style="text-align: center;">
//           <label for="swal-input1">Amount: <strong>$30</strong></label>
//         </div>
//         <input id="swal-input1" class="swal2-input" placeholder="Enter your card number" type="text" 
//                maxlength="16" pattern="\\d{16}" inputmode="numeric" style="margin-top: 15px;">
//         <input id="swal-input3" class="swal2-input" placeholder="MM/YY" type="text" 
//                pattern="(0[1-9]|1[0-2])\\d{2}" maxlength="4" style="margin-top: 15px;">
//         <input id="swal-input2" class="swal2-input" placeholder="Enter CVV" type="text" 
//                maxlength="3" pattern="\\d{3}" inputmode="numeric">
//       `,
//       focusConfirm: false,
//       showCancelButton: true,
//       confirmButtonText: 'Pay',
//       cancelButtonText: 'Cancel',
//       preConfirm: () => {
//         const cardNumber = document.getElementById('swal-input1').value;
//         const expiryDate = document.getElementById('swal-input3').value;
//         const cvv = document.getElementById('swal-input2').value;
  
//         if (!cardNumber || !expiryDate || !cvv) {
//           Swal.showValidationMessage('Please enter your card number, expiry date, and CVV');
//           return false;
//         }
//         return { cardNumber, expiryDate, cvv };
//       },
//       customClass: {
//         popup: 'custom-swal-popup',
//       },
//     });
  
//     if (formValues) {
//       // Simulate payment success after user submits details
//       localStorage.setItem('paymentStatus', 'true');
//       setIsPaymentDone(true);
//     }
//   };
  

//   return (
//     <>
//       <div className="container-fluid">
//       <div style={{textAlign:'center'}}>
//       <AdBanner/>
//       </div>
//         <div>
//           <div className="col-6 chatitem">
//               {messages.map((msg, index) => {
//                 const userName = getStringBeforeAtSymbol(extractLastPathPart(msg.from));
//                 const alignStyle = CurrentUser === extractLastPathPart(msg.from) ? 'end' : 'left';
//                 const marginRStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
//                 const marginTStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
//                 const isGif = msg.message.endsWith('.gif');
//                 const isPng = msg.message.endsWith('.png');
//                 const isWav = msg.message.endsWith('.wav');

//                 if (isGif) {
//                   return null;
//                 }
//                 if (isPng) {
//                   return (
//                     <div key={index} style={{ textAlign: alignStyle, marginRight: marginRStyle }}>
//                       <img src={`https://watchparty.tpvai.com/b${msg.message}`} alt="png" width="75" height="75" />
//                       <span className="autherusername">{userName}</span>
//                     </div>
//                   );
//                 }
//                 if (isWav) {
//                   return (
//                     <div key={index} onClick={() => setCurrentAudioIndex(index)} style={{ textAlign: 'left' }}>
//                       <audio controls ref={(el) => (audioRefs.current[index] = el)} src={msg.message} />
//                       <span className="autherusername">{userName}</span>
//                     </div>
//                   );
//                 }
//               })}

//           </div>
//         </div>
//         <div className="row">
//           <div className="col-6 text-sm-end">
//           </div>
//         </div>
//       </div>

//       <footer className="fixed-bottom p-3">
//         <div className="container-fluid">
//           <div className="row justify-content-center">
//             <div className="col-auto">
//               <div className="card">
//                 <div className="card-body">
//                 <div className="container text-center mt-4" id="emoji-gallery" tabIndex="0" style={{ margin: 0, padding: 0 }}>
//   {loading ? (
//     <div className="spinner-border" role="status">
//       <span className="visually-hidden">Loading...</span>
//     </div>
//   ) : (
//     <>
//       <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
//         {currentEmojis.map((emoji, index) => (
//           <div
//             key={index}
//             className="col-4 d-flex flex-column align-items-center position-relative px-1" // Added px-1 for spacing
//             ref={(el) => (emojiRefs.current[index] = el)}
//             onClick={() => handleImageClick(index, emoji)}
//             style={{ margin: 0, padding: 0 }}
//           >
//             {emoji.paid && (
//               <span style={{position: 'absolute', backgroundColor: '#dfb400', color: '#000', width: '21px', height: '21px', borderRadius: '50%', display: 'inline-block', textAlign: 'center', marginLeft:'47px'}}>
//                 P
//               </span>
//             )}
//             <img
//               src={`https://watchparty.tpvai.com/b${emoji.url}`}
//               alt="emoji"
//               className="img-fluid"
//               style={{
//                 width: "23px",
//                 height: "23px",
//                 objectFit: "cover",
//               }}
//             />
//             <span className="">{index + 1}</span>
//           </div>
//         ))}
//       </div>

//       <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
//         <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
//           <div style={{ cursor: "pointer" }} onClick={handlePrevious}>
//             <img
//               src="https://cdn-icons-png.flaticon.com/512/608/608283.png"
//               alt="previous"
//               className="img-fluid"
//               style={{
//                 width: "23px",
//                 height: "23px",
//                 objectFit: "cover",
//               }}
//             />
//             <p className="mt-2">8</p>
//           </div>
//         </div>
//         <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
//           <div style={{ cursor: "pointer" }}>
//             <img
//               src="https://watchparty.tpvai.com/b/uploads/start.png"
//               alt="start"
//               className="img-fluid"
//               style={{
//                 width: "23px",
//                 height: "23px",
//                 objectFit: "cover",
//               }}
//             />
//             <p className="mt-2">9</p>
//           </div>
//         </div>
//         <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
//           <div style={{ cursor: "pointer" }} onClick={handleNext}>
//             <img
//               src="https://cdn-icons-png.flaticon.com/512/626/626043.png"
//               alt="next"
//               className="img-fluid"
//               style={{
//                 width: "23px",
//                 height: "23px",
//                 objectFit: "cover",
//               }}
//             />
//             <p className="mt-2">0</p>
//           </div>
//         </div>
//       </div>
//     </>
//   )}
// </div>
//                 </div>
//               </div>

//             </div>
//           </div>
//         </div>
//       </footer>
//     </>
//   );
// };

// export default ChatScreen;
